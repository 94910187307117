import React from "react";
import { graphql } from "gatsby";
import Hidden from "@material-ui/core/Hidden";
import { Link, navigate } from "gatsby-plugin-intl";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Pagination from "../components/pagintaion";
import ContainerBox from "../components/containerBox";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { CommonJumbotron } from "../components/jumbotron";
import { formatDate } from "../utils";
import { IFluidObject } from "gatsby-background-image";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: "160px",
    paddingBottom: "160px",
  },
  listHeader: {
    height: "85px",
    borderTop: "2px solid #858585",
    borderBottom: "1px solid rgb(133, 133, 133)",
    margin: 0,
    display: "flex",
    "& span": {
      fontWeight: "700",
    },
  },
  list: {
    paddingTop: 0,
  },
  listItem: {
    height: "80px",
    borderBottom: "1px solid rgba(180, 180, 180, 0.6)",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
      display: "block",
      position: "relative",
      height: "110px",
    },
  },
  titleText: {
    paddingRight: "20px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    flex: 4,
    [theme.breakpoints.down("md")]: {
      paddingTop: "15px",
    },
  },
  titleHeaderText: {
    margin: 0,
    paddingRight: "20px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    flex: 4,
    "& span": {
      fontSize: "24px",
    },
  },
  dateText: {
    fontSize: "20px",
    textAlign: "left",
    flexShrink: 0,
    flex: 1,
    minWidth: "100px",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      bottom: 0,
      paddingBottom: "23px",
    },
  },
  postLink: {
    textDecoration: "none",
    color: "#858585",
  },
  listSection: {
    marginBottom: "80px",
  },
  pagination: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
}));

interface NodeType {
  node: {
    id: string;
    created_at: string;
    feature_image: string;
    title: string;
    ghostId: string;
  };
}
interface IRPageProps {
  data: {
    allGhostPost: {
      edges: Array<NodeType>;
    };
    backgroundPlaceholder: {
      childImageSharp: {
        fluid: IFluidObject | IFluidObject[] | (IFluidObject | string)[];
      };
    };
  };
  pageContext: {
    currentPage: number;
    numPages: number;
    language: string;
  };
}
const IRPage = ({ data, pageContext }: IRPageProps) => {
  const classes = useStyles();
  const {
    allGhostPost: { edges },
  } = data;
  const { currentPage, numPages, language } = pageContext;
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === 1) {
      navigate("/ir");
      return;
    }
    navigate(`/ir/page/${value}`);
  };

  return (
    <Layout language={language}>
      <SEO title="Notice" />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={"Notice"}
      />

      <main className={classes.content}>
        <ContainerBox>
          <div className={classes.listSection}>
            <ListItem className={classes.listHeader}>
              <ListItemText
                className={classes.titleHeaderText}
                primary="Title"
              />
              <Hidden mdDown>
                <ListItemText className={classes.dateText} primary={"Date"} />
              </Hidden>
            </ListItem>

            <List
              className={classes.list}
              aria-label="secondary mailbox folders"
            >
              {edges.map(item => (
                <Link
                  key={item.node.id}
                  to={`/ir/${item.node.ghostId}`}
                  className={classes.postLink}
                >
                  <ListItem
                    button
                    className={classes.listItem}
                    id={item.node.id}
                  >
                    <ListItemText
                      disableTypography
                      className={classes.titleText}
                      primary={item.node.title}
                    />
                    <ListItemText
                      className={classes.dateText}
                      primary={formatDate(item.node.created_at)}
                    />
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
          <Pagination
            count={numPages}
            currentPage={currentPage}
            onChange={handleChange}
            className={classes.pagination}
          />
        </ContainerBox>
      </main>
    </Layout>
  );
};

export default IRPage;

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: published_at }
      filter: { tags: { elemMatch: { name: { eq: "IR" } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          created_at
          feature_image
          title
          ghostId
        }
      }
    }
    backgroundPlaceholder: file(
      relativePath: { eq: "ir/img-title-photo-ir@3x.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
